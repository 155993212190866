// eslint-disable-next-line no-unused-vars
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

// Pages
import ServiceDetail from "./pages/Services";
import EditProfile from "./pages/EditProfile";
import RegisterScreen from "./pages/Register";
import LoginScreen from "./pages/Login";
//import HomeScreen from "./pages/Home";
import Profile from "./pages/Profile";

import ServiceNew from "./pages/CreateService";
import MisServicios from "./pages/ABM_Servicios/MisServicios";
import EditarServicio from "./pages/ABM_Servicios/EditarServicio";
import RegisterReferralsScreen from "./pages/RegisterReferrals/RegisterReferralsScreen";


// Components
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';

// Store
import { useStore } from "./store";
import AdminPage from "./pages/Admin";
import EditService from "./pages/EditService";

const HomeScreen = lazy(() => import("./pages/Home"));

function App() {
  const { showSidebar, login } = useStore();


  return (
    <>
      <Router>
        <Navbar />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/register" element={<RegisterScreen />} />
            {
              login ? (<Route path="/edit-profile" element={<EditProfile />} />
              ) : (
                <Route path="/edit-service/:id" element={<Navigate to="/login" />} />
              )
            }
            {login ? (
              <Route path="/edit-service/:id" element={<EditService />} />
            ) : (
              <Route path="/edit-service/:id" element={<Navigate to="/login" />} />
            )}
            <Route path="/service/:id" element={<ServiceDetail />} />
            <Route path="/ABM_Servicios/NuevoServicio" element={<ServiceNew />} />
            <Route path="/ABM_Servicios/MisServicios" element={<MisServicios />} />
            <Route path="/referrals/:ReferralLink" element={<RegisterReferralsScreen />} />
            <Route path="/ABM_Servicios/EditarServicio/:id" element={<EditarServicio />} />

          </Routes>
        </Suspense>
        {showSidebar && <Sidebar />}
      </Router>
    </>
  );
}

export default App;
