import React from 'react';
import { useStore } from '../../store';
import useIsFavorite from '../../hook/favorite';

function ServiceProviderDetails({title, description, reviews}) {

    const {addFavorite, user } = useStore();
    const serviceId = window.location.href.split("/").pop();

    const isFavorite = useIsFavorite(serviceId);

    const handleAddFavorite = () => {
        //la location es asi http://localhost:3000/service/1 necesito solo el utlimo numero
        const location = window.location.href.split("/").pop();

        addFavorite(location);
    }

    return (
        <div className="service-provider-details">
            <h1>{title}</h1>
            <p>{description}</p>

            <div className="rating">
                <span>⭐</span> 
                <span>Total de reseñas: {reviews ? reviews.length : 0}</span>
            </div>

            <div className="actions">
                {/* <button>Agregar revisión</button> */}
                {/* <button>Compartir</button> */}
                <button onClick={handleAddFavorite}>{isFavorite ? 'Favorito' : 'Agregar a Favoritos'}</button>
            </div>
        </div>
    );
}

export default ServiceProviderDetails;