import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import axios from "axios";




const HTTP = {
  GET: async (url, headers = {}) => {
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    return response.json();
  },
  POST: async (url, body, headers = {}) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    return response.json();
  },
  PUT: async (url, body, headers = {}) => {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    return response.json();
  },
  DELETE: async (url, headers = {}) => {
    const response = await fetch(url, {
      method: "DELETE",
      headers: headers,
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    return response.json();
  },
};

export const URL = {
  URL_API: process.env.REACT_APP_URL_API,
};

const ROUTE = "/api/auth";

export const useStore = create(
  persist(
    (set, get) => ({
      service: [],
      user: {},
      login: false,
      showSidebar: false,
      categories: null,
      search : '',
      whatsappClicks: 0, // Agregado para contar clics de WhatsApp
      error: {
        login: {
          status: false,
          message: "",
        },
        register: {
          status: false,
          message: "",
        }
      },

      incrementWhatsappClicks: () => {
        const currentClicks = get().whatsappClicks;
        set({ whatsappClicks: currentClicks + 1 });
      },
      resetWhatsappClicks: () => {
        set({ whatsappClicks: 0 });
      },
      init: async () => { },

      getUserProfile: async (id) => {
        const { data } = await axios.get(`${URL.URL_API}/api/user/${id}`);
        return data
      },
      updateProfile: async (user) => {
        const { data } = await axios.put(
          `${URL.URL_API}/api/user/${get().user.id}`,
          user,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        set({ user: data });
      },
      /*--------------------------- */
      /* AUTH */

      authLogin: async (usuario) => {
        try {
          const result = await axios.post(
            URL.URL_API + ROUTE + "/login",
            usuario
          );
          console.log(result)
          if(result.data.status !== 200){
            throw new Error(result.data.message)
          }

          localStorage.setItem("token", result.token);
          
          set({ user: result.data.usuario, login: true, error: null });
          return result;

        } catch (error) {
          console.log("Erorr : ", error)
          const currentError = get().error;
          set({ error: { ...currentError, login: { status: true, message: error.response.data.message } } })
        }
      },


      authRegister: async (usuario) => {
        const currentError = get().error;
        try {
          const result = await HTTP.POST(
            URL.URL_API + ROUTE + "/register",
            usuario
          );
          if (!result.ok) {
            throw new Error({ status: result.status, message: result.message });
          } else {
            localStorage.setItem("token", result.token);
            set({ user: result.usuario, login: true, error: null });
            return result;
          }
        } catch (error) {
          set({ error: { ...currentError, register: { status: true, message: error.response.data.message } } })
        }
      },

      authRegisterGoogle: async (usuario) => {
        const currentError = get().error;
        try {
          const result = await axios.post(
            URL.URL_API + ROUTE + "/register/google",
            usuario
          );
          localStorage.setItem("token", result.token);

          set({ user: result.usuario, login: true, error: null});
          return result;
        } catch (error) {

          set({ error: { ...currentError, register: { status: true, message: error.response.data.message } } })
        }
      },
      authVerifyToken: () => { },


      authLogout: (navigate) => {
        localStorage.removeItem("token");
        set({ user: {}, login: false, showSidebar: false });
        navigate("/");
      },

      loginGoogle: async (user, navigate) => {
        try {

          const { data } = await axios.post(
            `${URL.URL_API}/api/auth/login/google/${user.uid}`,
          );

          localStorage.setItem("token", data.token);
          set({ user: data.usuario, login: true });
          navigate("/")
        } catch (error) {
          const currentError = get().error;
          set({ error: { ...currentError, login: { status: true, message: error.response.data.message } } })
        }
      },

      /* AUTH */
      /*--------------------------- */


      /*--------------------------- */
      /* ADMIN */

      getUsers: async () => {
        const token = localStorage.getItem("token");
        const { data } = await axios.get(`${URL.URL_API}/api/admin/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return data;
      },

      deleteServiceAdmin: async (id) => {
        const token = localStorage.getItem("token");
        await axios.delete(`${URL.URL_API}/api/service/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const currentService = get().service;

        const updatedServices = currentService.filter(
          (service) => service.id !== id
        );
        set({ service: updatedServices });
      },

      aproveService: async (id) => {
        const token = localStorage.getItem("token");
        await axios.put(`${URL.URL_API}/api/admin/service/${id}`, {
          status: "Aprobado",
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const currentService = get().service;
        const updatedServices = currentService.map((service) => {
          if (service.id === id) {
            return { ...service, status: "aprobado" };
          }
          return service;
        });
        set({ service: updatedServices });
      },

      statusServiceChange: async (id, status) => {
        const token = localStorage.getItem("token");
        await axios.put(`${URL.URL_API}/api/admin/service/${id}`, {
          status: status,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const currentService = get().service;
        const updatedServices = currentService.map((service) => {
          if (service.id === id) {
            return { ...service, status: status };
          }
          return service;
        });
        set({ service: updatedServices });
      },


      /* ADMIN */
      /*--------------------------- */

      /*--------------------------- */
      /* SERVICES */

      getService: async () => {
        const { data } = await axios.get(`${URL.URL_API}/api/service`);
        set({ service: data });
      },

      createService: async (service) => {
        const { data } = await axios.post(
          `${URL.URL_API}/api/service`,
          service,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const currentUser = get().user;
        set({ user: { ...currentUser, service: data } });
        return data;
      },

      deleteService: async (id) => {
        

          await axios.delete(`${URL.URL_API}/api/service/${id}`);
        
        const currentUser = get().user;
        // Filtrar los servicios para excluir el eliminado
        const updatedServices = currentUser.Services.filter(
          (service) => service.id !== id
        );
        set({ user: { ...currentUser, service: updatedServices } });
      },

      updateService: async (service) => {
        console.log(service)
        const { data } = await axios.put(
          `${URL.URL_API}/api/service/detail/service/${service.id}`,
          service,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        return data;
      },


      getServiceById: async (id) => {
        const { data } = await axios.get(`${URL.URL_API}/api/service/${id}`);
        return data;
      },

      deleteImage: async (images) => {
        await axios.delete(`${URL.URL_API}/api/service/${images}/imagenes`);
      },


      /* SERVICES */
      /*--------------------------- */

      /*--------------------------- */
      /* USER */
      /*--------------------------- */

      putService: async (id, status, message) => {
        const { data } = await axios.put(`${URL.URL_API}/api/service/${id}`,
          {
            status: status,
            message: message,
          }
        );
        return data;
      },
      /*--------------------------- */
      /* CATEGORIES */
      handleModal: (value) => {
        set({ showSidebar: value });
      },
      getCategory: async () => {
        const { data } = await axios.get(`${URL.URL_API}/api/category`);

        return data;
      },
      /* CATEGORIES */
      /*--------------------------- */

      /*--------------------------- */
      /* REVIEWS */
      createReview: async (review) => {
        const { data } = await axios.post(`${URL.URL_API}/api/review`, review);
        
        return data;
      },

      addFavorite : async(id) => {
        
        const { data } = await axios.post(`${URL.URL_API}/api/user/favorite`, {
          idService: id,
          idUser: get().user.id
        });
        //actualiza en el store los favoritos de usuario
        const currentUser = get().user;
        set({ user: { ...currentUser, Favorites: data } });
      },

      setSearch: async(data) => {
        set({search : data})
      }
    }),
    {
      name: "app-storage", // Nombre del item en el almacenamiento
      storage: createJSONStorage(() => localStorage), // Usamos sessionStorage
    }
  )
);
