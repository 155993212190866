import React, { useState, useEffect } from "react";

import UserListAdmin from "../../components/Admin/UserList";
import ServiceListAdmin from "../../components/Admin/ServiceList";
import { useStore } from "../../store";
import "./style.css";

const AdminPage = () => {
  const { service, getUsers } = useStore();

  const [users, setUsers] = useState([]);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await getUsers();

      setUsers(response);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  return (
    <div className="ctn-admin">
      <div>
        <button onClick={() => setFlag(!flag)} className={flag ? "on" : "off"}>
          Usuarios
        </button>
        <button onClick={() => setFlag(!flag)} className={!flag ? "on" : "off"}>
          Servicios
        </button>
      </div>
      {flag ? (
        <UserListAdmin users={users} />
      ) : (
        <ServiceListAdmin services={service} />
      )}
    </div>
  );
};

export default AdminPage;
