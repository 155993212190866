import React, { useState } from "react";
import UserModal from "./UserModal"; // Asegúrate de importar el componente Modal


const UserListAdmin = ({ users }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Función para abrir el modal
  const openModal = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setSelectedUser(null);
    setIsModalOpen(false);
  };
  return (
    <div>
      <h1>Lista de usuarios</h1>
      <ul>
        {users.map((user) => (
          <div key={user.id} className="ctn-user-admin">
            <img
              className="img-photo-admin"
              src={
                user.photo
                  ? `https://www.contrataexpertos.com.ar/Perfil/Usuarios/${user.photo}`
                  : "https://www.contrataexpertos.com.ar/Perfil/Usuarios/edit-profile-vector-icon.jpg"
              }
              alt="User"
            />
            <div className="ctn-user-admin-info">
              <h3>
                {user.firstName} {user.lastName}
              </h3>
              <p>{user.idRol === '2' && 'Admin'}</p>
              <p>{user.idRol === '1' && 'Experto'}</p>
            </div>
            <button onClick={() => openModal(user)}>Administrar</button>
          </div>
        ))}
      </ul>
      {selectedUser && (
        <UserModal
          isOpen={isModalOpen}
          onClose={closeModal}
          user={selectedUser}
        />
      )}
    </div>
  );
};

export default UserListAdmin;
