// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import UserReviews from "../../components/UserReviews";
import ServicePhotos from "../../components/ServicePhotos";
import ServiceProviderDetails from "../../components/ServiceProviderDetails";

import { useStore } from "../../store";

import "./style.css";
import CreateReview from "../../components/CreateReview";

const Service = () => {
  const { getServiceById, user, login } = useStore();

  const navigate = useNavigate();

  const [service, setService] = useState(null);
  const [error, setError] = useState("");

  const location = useLocation();

  useEffect(() => {
    const fetchService = async () => {
      try {
        const data = await getServiceById(location.pathname.split("/")[2]);

        if (data) {
          setService(data.service);
        }
      } catch (error) {
        setError(`Error al cargar el servicio: ${error.message}`);
      }
    };

    fetchService();
  }, [getServiceById, location.pathname]);

  // Mostrar error si hay
  if (error) return <div>{error}</div>;
  
  return (
    <div className="container">
      {service ? (
        <>
          {service?.Owner.id === user?.id && (
            <button onClick={() => navigate(`/edit-service/${service.id}`)}>
              Editar mi servicio
            </button>
          )}
          <ServiceProviderDetails
            title={service.title}
            description={service.description}
            reviews={service.Owner?.Reviews ? service.Owner.Reviews : []}
          />
          <ServicePhotos images={service.Images} />
          {/* <ServiceLocation /> */}
          <UserReviews
            reviews={service?.Reviews ? service?.Reviews : []}
          />
          {
            login && (user.id !== service.Owner.id) && <CreateReview />
          }
          
        </>
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
};

export default Service;
