// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";

//Style
import "./style.css";

//Store
import { useStore } from "../../store";

//Router
import { useLocation, useNavigate } from "react-router-dom";

/*
- El usuario va elegir un barrio de la lista que nosotros proporcionamos, y en el caso de que el barrio no se encuentre puede seleccionar la opción otros, y se le abrira un inputs para poder tipear un barrio que no se encuentre en la lista, en el caso de que esto suceda, el administrador es el encargado de validar este nuevo barrio

- El usuario va a poder tener un boton en el cual puede seleccionar un archivo de tipo imagen(jpg, png, jpeg) o de documento (pdf) el cual debera ser validado por el administrador, y ese nombre que nosotros vamos a poder capturar cuando subis a nuestro modulo FTP debera ser guardado en la carpeta Perfil/Antecedentes

- El usuario va a poder agregrar su numero de telefono para que cuando un servicio se muestre en el inicio pueda ir con un enlace profundo al chat de whatsapp del experto

- El usuario va a poder agregar una foto de perfil el cual se va a usar nuestro modulo FTP y se guardara en la carpeta Perfil/Usuario


*/

export default function Profile() {
  const { getUserProfile, user } = useStore();
  const [servicesPerPage, setServicesPerPage] = React.useState(5);
  const [userProfile, setUser] = React.useState(null);
  const [index, setIndex] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSlice = (dir) => {
    if (dir) {
      if (index + servicesPerPage >= userProfile.Services.length) {
        return;
      }
      setIndex(index + servicesPerPage);
    } else {
      if (index - servicesPerPage < 0) {
        return;
      }
      setIndex(index - servicesPerPage);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const data = await getUserProfile(location.pathname.split("/")[2]);
      setUser(data);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const updateServicesPerPage = () => {
      const width = window.innerWidth;
      if (width < 576) {
        setServicesPerPage(1);
      } else if (width < 768) {
        setServicesPerPage(2);
      } else if (width < 992) {
        setServicesPerPage(3);
      } else {
        setServicesPerPage(5);
      }
    };

    window.addEventListener("resize", updateServicesPerPage);
    updateServicesPerPage();

    return () => {
      window.removeEventListener("resize", updateServicesPerPage);
    };
  }, []);

  return (
    <>
      {userProfile && (
        <div className="container">
          <div className="profile-container">
            <div className="profile-header">
              <h1>Perfil de Usuario</h1>
              {user.id === Number(location.pathname.split("/")[2]) && (
                <button
                  className="edit-profile-btn"
                  onClick={() => navigate(`/edit-profile`)}
                >
                  Editar Perfil
                </button>
              )}
            </div>
            <div className="profile-content">
              <div className="profile-sidebar">
                <img
                  className="profile-pic"
                  src={
                    userProfile.photo
                      ? `https://www.contrataexpertos.com.ar/Perfil/Usuarios/${userProfile.photo}`
                      : "https://www.contrataexpertos.com.ar/Perfil/Usuarios/edit-profile-vector-icon.jpg"
                  }
                  alt="User"
                />
                <h2>
                  {userProfile.firstName} {userProfile.lastName}
                </h2>
                <p style={{textAlign: 'start'}}>{userProfile.description}</p>
              </div>
              <div className="profile-main">
                <div className="profile-section">
                  <h3>Servicios Publicados</h3>
                  <div className="services-list">
                    {userProfile.Services.length > 0 ? (
                      <>
                        <button
                          className="btn-serv"
                          onClick={() => handleSlice(false)}
                          disabled={index - 3 < 0}
                        >
                          Prev
                        </button>
                        {userProfile.Services.slice(index, index + 3).map(
                          (service, index) => (
                            <div className="service-card" key={index}>
                              <div
                                style={{
                                  width: "10em",
                                  height: "3em", // Ajusta la altura para permitir dos líneas
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2, // Limita el texto a dos líneas
                                  WebkitBoxOrient: "vertical",
                                  lineHeight: "1.5em", // Ajusta la altura de línea para un mejor espaciado
                                }}
                              >
                                <strong
                                  onClick={() =>
                                    navigate(`/service/${service.id}`)
                                  }
                                >
                                  {service.title}
                                </strong>
                              </div>
                              <img
                                src={`https://www.contrataexpertos.com.ar/Servicios/Imagenes/${service.Images[0]?.url}`}
                                alt="Imagen del servicio"
                              />
                            </div>
                          )
                        )}
                        <button
                          className="btn-serv"
                          onClick={() => handleSlice(true)}
                          disabled={index + 3 >= userProfile.Services.length}
                        >
                          Next
                        </button>
                      </>
                    ) : (
                      <p>Aun no tiene servicios cargados</p>
                    )}
                  </div>
                </div>
                <div className="profile-section">
                  <h3>Recomendaciones</h3>
                  <div className="recommendations">
                    <p>
                      Comentario de un cliente satisfecho sobre el servicio
                      proporcionado por el usuario.
                    </p>
                    <p className="client">- Cliente 1</p>
                    <p>
                      Otro comentario positivo de un cliente contento con el
                      servicio.
                    </p>
                    <p className="client">- Cliente 2</p>
                  </div>
                </div>
                <div className="profile-section">
                  <h3>Rating</h3>
                  <div className="rating">
                    <span>⭐⭐⭐⭐☆</span>
                    <p>
                      Promedio de calificaciones basado en las opiniones de los
                      clientes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
