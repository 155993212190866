// eslint-disable-next-line no-unused-vars
import React from "react";

//Icon
import { CiMenuBurger } from "react-icons/ci";
import { CiLogin } from "react-icons/ci";

import style from "./style.module.css";

//Component
import ButtonComponent from "../Button";

//Store
import { useStore } from "../../store";
import { useNavigate, useLocation } from "react-router-dom";
import InputComponent from "../Input";

const Navbar = () => {
  const { login, handleModal, showSidebar, setSearch, search } = useStore();
  const navigate = useNavigate();
  const location = useLocation()
  
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    if (location.pathname === "/") {
      setShow(true);
    } else {
      setShow(false);
    }
  } , [location])


  return (
    <div className={style.navbar}>
      <a href="/">
        <img
          src={
            "https://www.contrataexpertos.com.ar/ImagenesSistema/LogoContrataExpertos.jpeg"
          }
          alt="Logo"
        />
      </a>
      {
        show && (
          <InputComponent placeholder={"Buscar"} set={setSearch} value={search} />
        )
      }
      {login ? (
        <ButtonComponent
          icon={<CiMenuBurger size={35} />}
          func={() => handleModal(!showSidebar)}
        />
      ) : (
        <ButtonComponent
          icon={<CiLogin size={35} />}
          func={() => navigate("/login")}
        />
      )}
    </div>
  );
};

export default Navbar;
